const config = require('../../config/config');

let returnJsonWithAuth = (res, state, postAuthCallback) => {
    if (res.status === 401 || res.status === 403) {
        fetch(config.adminAuthApiHost + "token", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                code: state.authCode,
                redirect: window.location.origin
            }),
            credentials: "include"
        }).then(result => {
            if (result.status === 401) {
                window.location.href = config.tokenUrl + window.location.origin + window.location.pathname;
                return;
            }
            if (state.redirect) {
                window.location.href = state.redirect;
                return;
            }
            postAuthCallback();
        }, error => {
            console.log(error);
        });
        return;
    }

    return res.json();
}

module.exports = returnJsonWithAuth;