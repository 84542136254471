import React, { Component } from 'react';

const returnJsonWithAuth = require('../shared/authHandler');
var config = require('../../config/config');

class ResolutionDialog extends Component {

    constructor(props) {
        super();
        this.state = {
            item: props.item,
            checkedItems: props.checkedItems,
            toggleModalFunction: props.toggleResolutionDialog,
            statusValues: ["Resolved", "PermanentError"],
            statusToSet: "Resolved"
        };

        this.cancel = this.cancel.bind(this);
        this.submitStatus = this.submitStatus.bind(this);
        this.statusChanged = this.statusChanged.bind(this);
    }

    componentDidMount() {
    }

    cancel() {
        this.state.toggleModalFunction();
    }

    submitStatus() {
        if (this.state.item) {
            fetch(config.errorApiHost + "error/" + this.state.item.CorrelationId + "/resolve", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'AnyVal'
                },
                credentials: "include",
                body: JSON.stringify({
                    Status: this.state.statusToSet
                })
            })
                .then(res => returnJsonWithAuth(res, this.state, this.state.toggleModalFunction).then(() => {
                    this.state.toggleModalFunction();
                }),
                    (error) => {
                        debugger;
                    })
        }
        else {
            fetch(config.errorApiHost + "error/bulkResolve", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'AnyVal'
                },
                body: JSON.stringify({
                    CorrelationIds: this.state.checkedItems,
                    Status: this.state.statusToSet
                }),
                credentials: "include"
            })
                .then(res => returnJsonWithAuth(res, this.state, () => this.submitStatus()))
                .then(json => {
                    console.log(json);
                    if (json) {
                        this.state.toggleModalFunction();
                    }

                },
                    (error) => {
                        debugger;
                        this.loadData();
                    });
        }

    }


    statusChanged = (event) => {
        this.setState({
            statusToSet: event.target.value
        });
    }


    render() {
        return (
            <div>
                <div>Set Status:</div>
                <select onChange={this.statusChanged}>
                    {this.state.statusValues.map(item => (
                        <option value={item} key={item}>{item}</option>
                    ))}
                </select>
                <br />
                <button onClick={this.cancel}>Cancel</button>  <button onClick={this.submitStatus}>Submit</button>
            </div>
        );
    }
}

export default ResolutionDialog;