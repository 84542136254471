import React from 'react';
import ReactDOM from 'react-dom';
import Home from './app/home/home';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

ReactDOM.render((
  <BrowserRouter
    forceRefresh={true}>
    <Switch>
      <Route exact path='/' component={Home} />
      <Route exact path='/pending' component={Home} />
      <Route path='/about' component={About} />
    </Switch>
  </BrowserRouter>
), document.getElementById('root'));

function About() {
  return (
    <div>
      <h2>About</h2>
    </div>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
