import React, { Component } from 'react';

class BulkSubmitSuccessDialog extends Component {

    constructor(props) {
        super();
        this.state = {
            urls: this.createUrlElements(props.items),
            toggleModalFunction: props.toggleDialog
        };
        this.cancel = this.cancel.bind(this);
    }

    componentDidMount() {
    }

    cancel() {
        this.state.toggleModalFunction();
    }

    createUrlElements(items) {
        let returnVal = [];
        let counter = 1;
        for (let item of items) {
            returnVal.push({
                counter,
                url: item
            });
        };
        return returnVal;
    }

    render() {
        return (
            <div>
                <div>Urls:</div>
                {
                    this.state.urls.map(item => (
                        <div key={item.url}><a href={item.url} target="_blank" rel="noreferrer">Job {item.counter}</a><br /></div>
                    ))
                }
                <br />
                <button onClick={this.cancel}>Cancel</button>
            </div>
        );
    }
}

export default BulkSubmitSuccessDialog;