import React, { Component } from 'react';
import './home.css';
import ResolutionDialog from '../dialogs/resolutionDialog';
import BulkSubmitSuccessDialog from '../dialogs/bulkSubmitSuccessDialog';
import Modal from 'react-modal';


const queryString = require('query-string');
const config = require('../../config/config');
const moment = require('moment');
const returnJsonWithAuth = require('../shared/authHandler');

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

class Home extends Component {
    constructor(props) {
        super(props);
        var parsedQueryString = queryString.parse(this.props.location.search);
        this.state = {
            error: null,
            isLoaded: false,
            data: [],
            isResolutionDialogOpen: false,
            isBulkSubmitSuccessDialogOpen: false,
            selectedItem: null,
            disableSubmit: false,
            authCode: parsedQueryString.code,
            redirect: parsedQueryString.state,
            checkedValues: [],
            bulkSubmitUrls: [],
            previousTokens: [],
            searchLimit: parsedQueryString.searchLimit ? decodeURIComponent(parsedQueryString.searchLimit) : 20,
            disableNext: true,
            isPending: props.location.pathname === "/pending"
        };
        this.toggleResolutionDialog = this.toggleResolutionDialog.bind(this);
        this.toggleBulkSubmissionSuccessDialog = this.toggleBulkSubmissionSuccessDialog.bind(this);
        this.loadData = this.loadData.bind(this);
        this.onCheck = this.onCheck.bind(this);
        this.toggleChecks = this.toggleChecks.bind(this);
        this.submitChecked = this.submitChecked.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.togglePageStatus = this.togglePageStatus.bind(this);
    }

    componentDidMount() {
        Modal.setAppElement('body');
        this.loadData();
    }

    loadData() {
        var url = this.createSearchUrl();

        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'AnyVal'
            },
            credentials: "include"
        })
            .then(res => returnJsonWithAuth(res, this.state, this.loadData))
            .then((result) => {
                if (result) {
                    var tmpToken = null;
                    var disableNext = true;
                    if (result.PageToken !== "{}") {
                        tmpToken = result.PageToken;
                        disableNext = false;
                    }
                    this.setState({
                        isLoaded: true,
                        items: result.Errors,
                        pageToken: tmpToken,
                        disableNext
                    });
                }
            },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    createSearchUrl() {
        var status = this.state.isPending ? "Pending" : "Error"
        var url = `${config.errorApiHost}errors?status=${status}&searchLimit=${this.state.searchLimit}`;
        url = this.appendSearch(url, "pageToken");
        url = this.appendSearch(url, "companyId");
        url = this.appendSearch(url, "orderId");
        url = this.appendSearch(url, "cs2System");

        return url;
    }

    appendSearch(url, key) {
        var parsedQueryString = queryString.parse(this.props.location.search);
        if (parsedQueryString[key]) {
            url = url + `&${key}=${encodeURIComponent(parsedQueryString[key])}`
        }
        return url;
    }

    nextPage() {
        this.setState({
            isLoaded: false,
            previousTokens: [...this.state.previousTokens, this.state.pageToken]
        });
        this.props.history.push({
            search: `?pageToken=${this.state.pageToken}&searchLimit=${this.state.searchLimit}`,
            pathname: "/"
        });
    }

    togglePageStatus() {
        if (this.state.isPending) {
            this.props.history.push({
                pathname: "/"
            });
        }
        else {
            this.props.history.push({
                pathname: "/pending"
            });
        }
    }

    toggleBulkSubmissionSuccessDialog() {
        if (this.state.isBulkSubmitSuccessDialogOpen) {
            this.loadData();
        }
        this.setState({
            isBulkSubmitSuccessDialogOpen: !this.state.isBulkSubmitSuccessDialogOpen
        });

    }

    toggleResolutionDialog(item) {
        if (!item && this.state.checkedValues.length === 0) {
            return;
        }
        this.setState({
            isResolutionDialogOpen: !this.state.isResolutionDialogOpen,
            selectedItem: item
        });
        if (!this.state.isResolutionDialogOpen) {
            this.loadData();
        }
    }

    toggleChecks() {
        let newChecks = [];
        if (this.state.checkedValues.length !== this.state.items.length) {
            for (let item of this.state.items) {
                newChecks.push(item.CorrelationId)
            };
        }
        this.setState({
            checkedValues: newChecks
        });
    }


    onCheck(checkbox, correlationId) {
        let vals = this.state.checkedValues;
        let index = vals.indexOf(correlationId)
        if (index === -1) {
            vals.push(correlationId)
        }
        else {
            vals.splice(index, 1);
        }
        this.setState({
            checkedValues: vals
        });
    }

    submitChecked() {
        this.setState({
            disableSubmit: true
        });
        fetch(config.errorApiHost + "error/bulkResubmit", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'AnyVal'
            },
            body: JSON.stringify({
                CorrelationIds: this.state.checkedValues
            }),
            credentials: "include"
        })
            .then(res => returnJsonWithAuth(res, this.state, () => this.submitChecked()))
            .then(json => {
                console.log(json);
                if (json) {
                    this.setState({
                        disableSubmit: false,
                        bulkSubmitUrls: json.urls,
                        isBulkSubmitSuccessDialogOpen: true
                    });
                }

            },
                (error) => {
                    debugger;
                    this.loadData();
                });
    }

    resubmitError = (error) => {
        this.setState({
            disableSubmit: true
        });
        fetch(config.errorApiHost + "error/" + error.CorrelationId + "/resubmit", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'AnyVal'
            },
            credentials: "include"
        })
            .then(res => returnJsonWithAuth(res, this.state, () => this.resubmitError(error)))
            .then(json => {
                console.log(json);
                if (json) {
                    window.open(json.url, "_blank");
                    this.setState({
                        disableSubmit: false
                    });
                    this.loadData();
                }

            },
                (error) => {
                    debugger;
                    this.loadData();
                });
    }

    render() {
        const { error, isLoaded, items } = this.state;
        if (error) {
            return <div>Error: {error.message}</div>;
        } else if (!isLoaded) {
            return <div>Loading...</div>;
        } else {
            return (
                <div className="Home">
                    <button onClick={() => this.submitChecked()} disabled={this.state.disableSubmit}>Submit Checked</button>
                    <button onClick={() => this.toggleResolutionDialog(null)}>Resolve Checked</button>
                    <button onClick={() => this.toggleChecks()}>Toggle Checks</button>
                    <button onClick={() => this.nextPage()} disabled={this.state.disableNext}>Next Page</button>
                    <button onClick={() => this.togglePageStatus()}>Toggle Status</button>
                    <table>
                        <thead>
                            <tr>
                                <td>Select</td>
                                <td>System</td>
                                <td>Company Id</td>
                                <td>Order Id</td>
                                <td>Correlation Id</td>
                                <td>Status</td>
                                <td className="maxWidth">Error</td>
                                <td>Created</td>
                                <td>Modified By</td>
                                <td>Number of Files</td>
                                <td>Retry Count</td>
                                <td>Actions</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                items.map(item => (
                                    <tr key={item.CorrelationId}>
                                        <td><input type="checkbox" checked={this.state.checkedValues.indexOf(item.CorrelationId) !== -1} onChange={e => (this.onCheck(e, item.CorrelationId))} /></td>
                                        <td>{item.CS2System}</td>
                                        <td><a href={config.adminUiHost + 'app/companies/' + item.CompanyId + '/profile'} target="_blank" rel="noopener noreferrer">{item.CompanyId}</a></td>
                                        <td><a href={config.diagnosticUiHost + 'company/' + item.CompanyId + '/order/' + item.OrderId} target="_blank" rel="noopener noreferrer">{item.OrderId}</a></td>
                                        <td><a href={item.ExecutionUrl} target="_blank" rel="noopener noreferrer">{item.CorrelationId}</a></td>
                                        <td>{item.Status}</td>
                                        <td className="maxWidth">{item.ErrorMessage}</td>
                                        <td>{moment(item.CreatedDate).fromNow()}</td>
                                        <td>{item.ModifiedBy}</td>
                                        <td>{item.CacheFiles.length}</td>
                                        <td>{item.RetryCount}</td>
                                        <td><button onClick={() => this.toggleResolutionDialog(item)}>Resolve</button><button onClick={() => this.resubmitError(item)} disabled={this.state.disableSubmit}>Resubmit</button></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <Modal
                        isOpen={this.state.isResolutionDialogOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.cancel}
                        style={customStyles}
                        contentLabel="Create Modal">
                        <ResolutionDialog toggleResolutionDialog={this.toggleResolutionDialog} item={this.state.selectedItem} checkedItems={this.state.checkedValues} />
                    </Modal>
                    <Modal
                        isOpen={this.state.isBulkSubmitSuccessDialogOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.cancel}
                        style={customStyles}
                        contentLabel="Create Modal">
                        <BulkSubmitSuccessDialog toggleDialog={this.toggleBulkSubmissionSuccessDialog} items={this.state.bulkSubmitUrls} />
                    </Modal>
                </div>
            );
        }
    }
}

export default Home;