let environmentName = "manual";

let config = {
    errorApiHost: "https://api." + environmentName + ".errors.closesimple.com/",
    adminUiHost: "https://admin." + environmentName + ".web.cdn.closesimple.com/",
    diagnosticUiHost: "https://" + environmentName + ".diagnostics.closesimple.com/",
    adminAuthApiHost: "https://api.auth" + environmentName + ".admin.closesimple.com/",
    adminAuthHost: "https://auth" + environmentName + ".admin.closesimple.com/",
    cognitoClientId: "fi39d7vp3sv8016ed0hcq2c0f"
}

config.tokenUrl = config.adminAuthHost + "login?response_type=code&client_id=" + config.cognitoClientId + "&redirect_uri=" + window.location.origin + "&scope=openid+profile+aws.cognito.signin.user.admin&state="

module.exports = config;
